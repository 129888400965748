import { Colors, Img, Lightning, Registry, Utils } from "@lightningjs/sdk";
import { BrandingModel, MovieModel } from "../../lib/models";
import theme from "../../lib/theme";
import configs from "../../lib/configs";

interface MediaDynamicBannerTemplateSpec extends Lightning.Component.TemplateSpec {
    Background: {
        Placeholder: object;
        MovieBackdrop: {
            Placeholder: object;
            Backdrops: {
                Backdrop: object;
                BackdropHelper: object;
            };
            BackgroundHalf: object;
            GradientFull: object;
            GradientLeft: object;
        };
    };
}

export class MediaDynamicBanner
    extends Lightning.Component<MediaDynamicBannerTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MediaDynamicBannerTemplateSpec>
{
    _isFullScreen = false;
    _timeout: undefined | number;
    _lastSrc: undefined | string;
    _index = 0;
    _imgWidth = theme.banner.h * 1.77;

    _boundEventHandlers: any = {};

    readonly Background = this.getByRef("Background")!;
    readonly MovieBackdrop = this.Background.getByRef("MovieBackdrop")!;
    readonly GradientLeft = this.MovieBackdrop.getByRef("GradientLeft")!;
    readonly GradientFull = this.MovieBackdrop.getByRef("GradientFull")!;
    readonly BackgroundHalf = this.MovieBackdrop.getByRef("BackgroundHalf")!;

    readonly Placeholder = this.MovieBackdrop.getByRef("Placeholder")!;
    readonly Backdrops = this.MovieBackdrop.getByRef("Backdrops")!;
    readonly Backdrop = this.Backdrops.getByRef("Backdrop")!;
    readonly BackdropHelper = this.Backdrops.getByRef("BackdropHelper")!;

    static override _template(): Lightning.Component.Template<MediaDynamicBannerTemplateSpec> {
        return {
            Background: {
                alpha: 1,
                w: theme.banner.w - theme.menu.w,
                h: theme.banner.h,
                rect: true,
                clipping: true,
                color: Colors(theme.color.container).get(),
                Placeholder: {
                    w: (w) => w,
                    h: (h) => h,
                    alpha: 1,
                    src: configs.banner.hasStaticBanner ? Utils.asset("images/media-banner.png") : undefined
                },
                MovieBackdrop: {
                    w: (w) => w,
                    h: (h) => h,
                    Placeholder: {
                        w:
                            (300 / Math.max(theme.mediaPlaceholder.w, theme.mediaPlaceholder.h)) *
                            theme.mediaPlaceholder.w,
                        h:
                            (300 / Math.max(theme.mediaPlaceholder.w, theme.mediaPlaceholder.h)) *
                            theme.mediaPlaceholder.h,
                        x: theme.banner.contentW + 450,
                        y: (h) => h / 2,
                        mountY: 0.5,
                        alpha: 0.01,
                        src: !configs.banner.omitPreview ? Utils.asset("images/placeholder.png") : undefined
                    },
                    Backdrops: {
                        alpha: 0,
                        w: configs.banner.useCoverUrl ? 300 : (w) => w,
                        h: configs.banner.useCoverUrl ? 300 : (h) => h,
                        x: configs.banner.useCoverUrl ? theme.banner.contentW + 450 : 0,
                        y: (h) => h / 2,
                        zIndex: configs.banner.useCoverUrl ? 10 : 0,
                        mountY: 0.5,
                        Backdrop: {
                            w: (w) => w,
                            h: (h) => h,
                            alpha: 0.01
                            // src: Utils.asset("images/background.png")
                        },
                        BackdropHelper: {
                            w: (w) => w,
                            h: (h) => h,
                            alpha: 0.01
                            // src: Utils.asset("images/background.png")
                        }
                    },
                    GradientFull: {
                        x: 0,
                        y: 0,
                        w: (w) => w,
                        h: (h) => h
                        // rect: true,
                        // colorLeft: Colors(theme.mediaBanner.gradientStart).alpha(1).get(),
                        // colorRight: Colors(theme.mediaBanner.gradientEnd).alpha(0.01).get()
                    },
                    BackgroundHalf: {
                        x: 0,
                        y: 0,
                        w: (w) => w,
                        h: (h) => h,
                        rect: true,
                        color: Colors(theme.mediaBanner.gradientStart).alpha(1).get(),
                        alpha: 0
                    },
                    GradientLeft: {
                        x: 0,
                        y: 0,
                        w: (w) => w,
                        h: (h) => h,
                        rect: true,
                        colorLeft: Colors(theme.mediaBanner.gradientStart).alpha(1).get(),
                        colorRight: Colors(theme.mediaBanner.gradientEnd).alpha(0.01).get()
                    }
                }
            }
        };
    }

    override _setup() {
        this._boundEventHandlers = {
            updateBackground: this._updateBackground.bind(this),
            hideBackground: this._hideBackground.bind(this),
            showBackground: this._showBackground.bind(this),
            clearBackground: this._clearBackground.bind(this)
        };
    }

    override _attach() {
        this.application.on("updateSelectedMovie", this._boundEventHandlers.updateBackground);
        this.application.on("hideBackground", this._boundEventHandlers.hideBackground);
        this.application.on("showBackground", this._boundEventHandlers.showBackground);
        this.application.on("clearBackground", this._boundEventHandlers.clearBackground);
    }

    override _detach() {
        this.application.off("updateSelectedMovie", this._boundEventHandlers.updateBackground);
        this.application.off("hideBackground", this._boundEventHandlers.hideBackground);
        this.application.off("showBackground", this._boundEventHandlers.showBackground);
        this.application.off("clearBackground", this._boundEventHandlers.clearBackground);
    }

    override _init() {
        this.Backdrop.on("txLoaded", () => {
            this.Backdrops.setSmooth("alpha", 1);
            this.Backdrop.setSmooth("alpha", 1);
            this.BackdropHelper.setSmooth("alpha", 0);

            if (!configs.banner.useCoverUrl) {
                this.BackgroundHalf.patch({
                    smooth: {
                        alpha: this._isFullScreen ? 0 : 1
                    }
                });
                this.GradientLeft.patch({
                    smooth: {
                        alpha: this._isFullScreen ? 0 : 1
                    }
                });

                this.GradientFull.patch({
                    smooth: {
                        alpha: this._isFullScreen ? 1 : 0
                    }
                });
            }
        });
        this.BackdropHelper.on("txLoaded", () => {
            this.Backdrops.setSmooth("alpha", 1);
            this.BackdropHelper.setSmooth("alpha", 1);
            this.Backdrop.setSmooth("alpha", 0);

            if (!configs.banner.useCoverUrl) {
                this.BackgroundHalf.patch({
                    smooth: {
                        alpha: this._isFullScreen ? 0 : 1
                    }
                });
                this.GradientLeft.patch({
                    smooth: {
                        alpha: this._isFullScreen ? 0 : 1
                    }
                });

                this.GradientFull.patch({
                    smooth: {
                        alpha: this._isFullScreen ? 1 : 0
                    }
                });
            }
        });

        for (const child of this.Backdrops.children) {
            child?.on("txError", () => {
                if (this._lastSrc && child.src && child.src.indexOf(this._lastSrc) > -1) {
                    this.Placeholder.setSmooth("alpha", 1);
                    this.Backdrops.setSmooth("alpha", 0);

                    if (!configs.banner.useCoverUrl) {
                        if (this._isFullScreen) {
                            this.GradientLeft.patch({
                                smooth: {
                                    alpha: 0
                                }
                            });

                            this.BackgroundHalf.patch({
                                smooth: {
                                    alpha: 0
                                }
                            });

                            child.patch({
                                w: (w) => w
                            });
                        } else {
                            this.GradientLeft.patch({
                                smooth: {
                                    alpha: 1
                                }
                            });

                            this.BackgroundHalf.patch({
                                smooth: {
                                    alpha: 1
                                }
                            });

                            this._setLimitedWidth();
                        }
                    }
                }
            });
        }

        if (!configs.banner.useCoverUrl) {
            this._setLimitedWidth();
        }
    }

    _setLimitedWidth() {
        const x = this._getXOffset();

        for (const child of this.Backdrops.children) {
            child?.patch({
                w: this._imgWidth,
                x: x
            });
        }

        this._adjustGradientPosition();
    }

    _adjustGradientPosition() {
        const x = theme.layout.screenW - theme.menu.w - this._imgWidth;

        this.GradientLeft.patch({
            x: x - 40,
            w: (w) => w - x + 40
        });

        this.BackgroundHalf.patch({
            w: x - 40
        });
    }

    _getXOffset() {
        return this._isFullScreen ? 0 : theme.layout.screenW - theme.menu.w - this._imgWidth;
    }

    _clearBackground() {
        this._lastSrc = undefined;
        this.MovieBackdrop.patch({
            alpha: 0
        });
        this.Backdrop.patch({
            src: undefined,
            alpha: 0.01
        });
        this.BackdropHelper.patch({
            src: undefined,
            alpha: 0.01
        });
    }

    _hideBackground() {
        this.Background.patch({
            smooth: {
                alpha: 0
            }
        });
    }

    _showBackground() {
        this.MovieBackdrop.patch({
            alpha: 1
        });
        this.Background.patch({
            alpha: 1
        });
    }

    _updateBackground(movie: MovieModel | BrandingModel) {
        const prevFullscreen = this._isFullScreen;
        this._isFullScreen = "backdropWideUrl" in movie && movie.type === "banner";
        const url = this._isFullScreen ? (movie as BrandingModel).backdropWideUrl : movie.backdropUrl;

        if (prevFullscreen && !this._isFullScreen) {
            this.BackgroundHalf.patch({
                alpha: this._isFullScreen ? 0 : 1
            });
            this.GradientLeft.patch({
                alpha: this._isFullScreen ? 0 : 1
            });

            this.GradientFull.patch({
                alpha: this._isFullScreen ? 1 : 0
            });
        }

        this.MovieBackdrop.patch({
            alpha: 1
        });

        if (!url) {
            this.Backdrops.setSmooth("alpha", 0);
            this.Placeholder.setSmooth("alpha", 1);

            this._lastSrc = undefined;
            return;
        }

        if (this._timeout) {
            Registry.clearTimeout(this._timeout);
            this._timeout = undefined;
        }

        if (url === this._lastSrc) return;

        this._timeout = Registry.setTimeout(() => {
            this._timeout = undefined;

            this._lastSrc = url;

            if (!configs.banner.omitPreview) {
                if (configs.banner.useCoverUrl) {
                    this.Backdrops.children[this._index]?.patch({
                        texture: Img(`${this._lastSrc}`).cover(300, 300),
                        alpha: 0.01
                    });
                } else {
                    const w = this._isFullScreen ? theme.banner.w - theme.menu.w : this._imgWidth;

                    this.Backdrops.children[this._index]?.patch({
                        texture: Img(
                            `${this._lastSrc}?width=${this._isFullScreen ? theme.banner.w : Math.floor(w / 2)}`
                        ).cover(w, theme.banner.h),
                        x: this._isFullScreen ? 0 : this._getXOffset(),
                        w: w,
                        alpha: 0.01
                    });
                }
            }

            this._index ^= 1;

            this.Backdrops.setSmooth("alpha", 1);
            this.Placeholder.setSmooth("alpha", 0.01);
        }, 250);
    }
}
