import { Colors, Lightning, Registry } from "@lightningjs/sdk";
import { LoadingCircle } from "../LoadingCircle/LoadingCircle";
import { SPLASH_TIME } from "../../lib/utils";
import theme from "../../lib/theme";

interface LoadingCircleTemplateSpec extends Lightning.Component.TemplateSpec {
    delay: number;
    Loading: typeof LoadingCircle;
    Splash: object;
    Background: object;
    isMain: boolean;
}

export class Loading
    extends Lightning.Component<LoadingCircleTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<LoadingCircleTemplateSpec>
{
    _delay?: number;
    readonly Background = this.getByRef("Background")!;
    readonly Loading = this.getByRef("Loading")!;

    static override _template(): Lightning.Component.Template<LoadingCircleTemplateSpec> {
        return {
            Background: {
                x: 0,
                y: 0,
                w: theme.layout.screenW,
                h: theme.layout.screenH,
                rect: true,
                color: Colors(theme.color.overlay).alpha(0.6).get(),
                alpha: 1
            },
            Loading: {
                w: theme.layout.screenW,
                h: theme.layout.screenH,
                type: LoadingCircle,
                alpha: 1
            }
        };
    }

    set delay(value: number) {
        this._delay = value;
    }

    override _active() {
        Registry.setTimeout(
            () => {
                this.tag("Loading")!.patch({
                    smooth: {
                        alpha: 1
                    }
                });

                this.tag("Background")!.patch({
                    smooth: {
                        alpha: 1
                    }
                });
            },
            this._delay || SPLASH_TIME + 200
        );
    }

    override _inactive() {
        // disable on first load to show splash in BG
        this.tag("Loading")!.patch({
            alpha: 1
        });
        this.tag("Background")!.patch({
            alpha: 1
        });
    }

    set isMain(value: boolean) {
        //
    }
}
