const theme = {
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flexStart",
  },
  flexRowAligned: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flexStart",
    alignItems: "center",
    gap: 10,
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flexStart",
  },
  layout: {
    screenW: 1920,
    screenH: 1080,
    marginX: 180,
    marginY: 70,
    gutterX: 16,
    gutterY: 52,
    focusScale: 1.1,
    menuW: 125,
    bannerH: 540,
    bannerW: 1920,
    bannerContentW: 868,
    bannerPadding: 87,
    contentY: 33,
    contentX: 53,
  },
  movie: {
    contentW: 1100,
  },
  banner: {
    w: 1920,
    h: 540,
    contentW: 780,
    padding: 80,
  },
  logo: {
    w: 193,
    h: 80,
    y: 40,
  },
  menu: {
    w: 140,
    activeW: 280,
    focus: "#0047FF",
    itemOffset: 21,
  },
  rowItem: {
    categoryH: 440,
    movieH: 290,
    containerW: 400,
    containerH: 225,
    posterW: 380,
    posterH: 210,
    posterImgW: 380,
    posterImgH: 210,
    highlightW: 3,
  },
  mediaPlaceholder: {
    w: 133,
    h: 60,
  },
  mediaItem: {
    posterW: 153,
    posterH: 230,
    posterImgW: 153,
    posterImgH: 230,
    highlightW: 3,
    gap: 36,
    radius: 6,
  },
  episodeItem: {
    posterW: 360,
    posterH: 203,
    posterImgW: 360,
    posterImgH: 203,
    highlightW: 3,
    radius: 6,
    gap: 36,
  },
  color: {
    primary: "#121212",
    focus: "#0047FF",
    container: "#000000",
    text: "#EBEAEE",
    textDark: "#EBEAEE",
    textHighlight: "#FFEE00",
    dark: "#0047FF",
    highlight: "#E7E5EE",
    contrast: "#ffffff",
    overlay: "#000000",
  },
  button: {
    textColor: "#ffffff",
    textColorFocus: "#231F31",
    bg: "#4A4A4A",
    bgFocus: "#ffffff",
  },
  mediaColors: {},
  mediaBanner: {
    gradientStart: "#000000",
    gradientEnd: "#000000",
  },
  keyboard: {
    color: "#121212",
    keyColor: "#121212",
    keyGap: 0,
    keyW: 95,
    keyH: 95,
    specialKeyH: 95,
    inputW: 572,
    inputBg: "#121212",
    keyboardW: 570,
    keyFocus: "#0047FF",
    keyText: "#ffffff",
    keyTextFocus: "#ffffff",
  },
  settings: {
    w: 540,
    itemH: 87,
  },
  progress: {
    offset: 260,
  },
};

export default theme;
