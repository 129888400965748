import { Lightning } from "@lightningjs/sdk";

interface LoadingCircleTemplateSpec extends Lightning.Component.TemplateSpec {
    Loading: object;
    Background: object;
    isAbsolute: boolean;
}

export class LoadingCircle
    extends Lightning.Component<LoadingCircleTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<LoadingCircleTemplateSpec>
{
    _animation: Lightning.types.Animation | undefined;
    readonly Loading = this.getByRef("Loading")!;

    static override _template(): Lightning.Component.Template<LoadingCircleTemplateSpec> {
        return {
            Loading: {
                x: (w) => w / 2 - 104 / 2,
                y: (h) => h / 2 - 104 / 2,
                h: 104,
                w: 104,
                texture: Lightning.Tools.getSvgTexture(
                    `data:image/svg+xml,${encodeURIComponent(`<svg width="186" height="186" viewBox="0 0 186 186" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M90 6C139.706 6 180 46.2944 180 96" stroke="url(#paint0_linear_434_276)" stroke-width="12" stroke-linecap="round"/>
                      <defs>
                      <linearGradient id="paint0_linear_434_276" x1="180" y1="101" x2="90" y2="6" gradientUnits="userSpaceOnUse">
                      <stop offset="0" stop-color="white" />
                      <stop offset="1" stop-color="white" stop-opacity="0"/>
                      </linearGradient>
                      </defs>
                      </svg>
                      `)}`,
                    104,
                    104
                ),
                zIndex: 10
            }
        };
    }

    set isAbsolute(value: boolean) {
        if (!value) {
            this.patch({
                x: 0,
                y: 0,
                h: 104,
                w: 104
            });
        }
    }

    override _captureKey(): boolean | void {
        //
    }

    override _init() {
        this._animation = this.Loading.animation({
            duration: 1.2,
            repeat: -1, // infinite
            actions: [{ p: "rotation", v: { 0: 0, 1: Math.PI * 2 } }]
        });
    }

    override _active() {
        this._animation && this._animation.start();
    }

    override _inactive() {
        this._animation && this._animation.stop();
    }
}
